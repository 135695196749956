import React, { createContext, useState } from "react";
// import { useEffect } from "react";
// import { v4 as uuid } from "uuid";

export const AppContext = createContext(null);

const AppContextProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    modal: false,
  });

  // const [token, setToken] = useState(uuid());

  // const resetToken = () => {
  //   setToken(uuid());
  // };

  // useEffect(() => {
  //   console.log(token);
  // }, [token]);

  return (
    <AppContext.Provider value={{ settings, setSettings }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
