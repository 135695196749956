import React, { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";

import { PoulesContext } from "../contexts/PoulesContext";

import Poule from "../components/Poule";
import { Download, RefreshCw } from "react-feather";

import { AppContext } from "../contexts/AppContext";
import GlobalModal from "../components/GlobalModal";
import DownloadModal from "../components/DownloadModal";

const Poules = () => {
  const { poules } = useContext(PoulesContext);
  const [printing] = useState(false);
  const { settings, setSettings } = useContext(AppContext);
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const regroupPoules = (e) => {
    e.preventDefault();

    ReactGA.event({
      category: "Gebruikersinteracties",
      action: "Click op 'opnieuw indelen'",
    });

    const s = { ...settings };
    s.modal = true;
    setSettings(s);
  };

  const handleDownlad = () => {
    ReactGA.event({
      category: "Gebruikersinteracties",
      action: "Click op 'download'",
    });

    setModal(true);
  };

  useEffect(() => {
    if (poules.length === 0) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="c-actions">
        <div className="o-wrapper o-container c-actions__container">
          <button className="c-button c-button--small" onClick={handleDownlad}>
            <Download size="20" /> <span>Download</span>
          </button>
          <button className="c-button c-button--small" onClick={regroupPoules}>
            <RefreshCw size="20" />
          </button>
        </div>
      </div>
      <div className="o-wrapper o-container">
        <h1>{poules.length} poules</h1>
        {!printing ? (
          <div className="o-grid">
            {poules.map((players, pouleIdx) => {
              return (
                <div className="o-grid__item" key={pouleIdx}>
                  <Poule id={pouleIdx + 1} players={players} />
                </div>
              );
            })}
          </div>
        ) : (
          <div>Printing</div>
        )}
      </div>

      <GlobalModal show={modal} onClose={() => setModal(false)}>
        <DownloadModal onClose={() => setModal(false)} />
      </GlobalModal>
    </>
  );
};

export default Poules;
