import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { PlayersContext } from "../contexts/PlayersContext";
import { AppContext } from "../contexts/AppContext";

import { ArrowRight } from "react-feather";

const Home = () => {
  // const history = useHistory();
  const location = useLocation();
  const { players, setPlayers } = useContext(PlayersContext);
  const { settings, setSettings } = useContext(AppContext);
  const [value, setValue] = useState(() => {
    return players.length ? players.join("\n") : "";
  });

  const userException = location.search === "?exceed_max_players=true";
  const maxParticipants = userException ? 128 : 64;

  const isDisabled = players.length < 3 || players.length > maxParticipants;

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isDisabled) {
      const s = { ...settings };
      s.modal = true;
      setSettings(s);
    }
  };

  useEffect(() => {
    const arr = value.split("\n").filter(Boolean);
    setPlayers(arr);
  }, [value, setPlayers]);

  return (
    <div className="o-wrapper o-container">
      <form onSubmit={handleSubmit}>
        <p>Vul één naam per regel in.</p>
        <div className="c-input">
          <textarea
            placeholder="Minimaal 3 namen..."
            onChange={handleChange}
            value={value}
          ></textarea>
        </div>
        {players.length > maxParticipants - 1 && (
          <p>
            <strong>
              Het maximum aantal van {maxParticipants} is bereikt.
            </strong>
          </p>
        )}
        <button
          type="submit"
          className="c-button c-button--expanded"
          disabled={isDisabled}
        >
          {players.length >= 3 ? (
            <>
              <span>{players.length} spelers indelen</span> <ArrowRight />
            </>
          ) : (
            <>Nog minimaal {3 - players.length} toevoegen</>
          )}
        </button>
      </form>
    </div>
  );
};

export default Home;
