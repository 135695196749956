import React, { createContext, useState, useEffect } from "react";

// import { AppContext } from "./AppContext";

export const PoulesContext = createContext(null);

const PoulesContextProvider = ({ children }) => {
  // const { resetToken } = useContext(AppContext);

  const [poules, setPoules] = useState(() => {
    const localData = sessionStorage.getItem("poules");
    return localData ? JSON.parse(localData) : [];
  });

  // const [pouleSettings, setPouleSettings] = useState(() => {
  //   const localData = sessionStorage.getItem("poule-settings");
  //   return localData ? JSON.parse(localData) : { min: 3, max: 6 };
  // });
  const [pouleSettings, setPouleSettings] = useState({ min: 3, max: 6 });

  useEffect(() => {
    sessionStorage.setItem("poules", JSON.stringify(poules));
  }, [poules]);

  // useEffect(() => {
  //   resetToken();
  // }, [poules, resetToken]);

  useEffect(() => {
    sessionStorage.setItem("poule-settings", JSON.stringify(pouleSettings));
  }, [pouleSettings]);

  return (
    <PoulesContext.Provider
      value={{ poules, setPoules, pouleSettings, setPouleSettings }}
    >
      {children}
    </PoulesContext.Provider>
  );
};

export default PoulesContextProvider;
