export const createFormat = (l, n) => {
  const s = ~~(l / n),
    r = l % n;

  return [...Array(r).fill(s + 1), ...Array(n - r).fill(s)];
};

export const createFormats = (l, min, max) => {
  const start = ~~(l / min),
    stop = ~~(l / max);

  let formats = [];

  for (let i = start; i >= stop; i--) {
    if (!i || (i === stop && l % max > 0)) break;
    formats.push(createFormat(l, i));
  }

  return formats;
};

export const createPoules = (p, n) => {
  const format = createFormat(p.length, n);
  const players = shuffle([...p]);

  return Array.from(format, (v, i) => players.splice(0, v));
};

export const shuffle = (arr) => {
  return arr
    .map((v) => [v, Math.random()])
    .sort((a, b) => a[1] - b[1])
    .map((v) => v[0]);
};

export const alpha = (i) => {
  return "abcdefghijklmnopqrstuvwxyz".substr(i, 1);
};

export const getOccurences = (arr) =>
  new Map([...new Set(arr)].map((x) => [x, arr.filter((y) => y === x).length]));
