const dev = process.env.NODE_ENV === "development";

const baseUrl = dev ? "http://localhost" : "https://api.dartschema.nl/v1";

export const http = (url, options) => {
  return fetch(`${baseUrl}${url}`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, application/pdf",
    },
    ...options,
  });
};
