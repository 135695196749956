import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import logo from "../logo.svg";

const Header = () => {
  const { pathname, search } = useLocation();

  const handleClick = () => {
    ReactGA.event({
      category: "Gebruikersinteracties",
      action: "Click on logo",
    });
  };

  useEffect(() => {
    ReactGA.pageview(
      `${pathname}${search}`,
      null,
      "Dartschema.nl | Applicatie"
    );
  }, [pathname, search]);

  return (
    <header className="c-header">
      <div className="o-wrapper o-container c-header__container">
        <Link to="/" className="c-header__logo" onClick={handleClick}>
          <img src={logo} alt="Dartschema.nl logo" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
