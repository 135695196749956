import React from "react";

const Poule = ({ id, players }) => {
  return (
    <div className="c-card">
      <div className="c-card__header">
        <strong>Poule {id}</strong>
      </div>
      <div className="c-card__content">
        <ol type="A" className="c-poule">
          {players.map((player, idx) => {
            return (
              <li key={idx}>
                <span>{player}</span>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default Poule;
