import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga";

import AppContextProvider from "./contexts/AppContext";
import PlayersContextProvider from "./contexts/PlayersContext";
import PoulesContextProvider from "./contexts/PoulesContext";

import Header from "./components/Header";
import Modal from "./components/Modal";
import Maintenance from "./components/Maintenance";

import Home from "./views/Home";
import Poules from "./views/Poules";

ReactGA.initialize("UA-62963679-1", {
  debug: false,
});

const maintenance = false;

const App = () => {
  return (
    <AppContextProvider>
      <PlayersContextProvider>
        <PoulesContextProvider>
          {maintenance ? (
            <Maintenance />
          ) : (
            <Router>
              <Header />
              <Routes>
                <Route path="/" element={<Home />} exact />
                <Route path="/poules" element={<Poules />} />
              </Routes>
              <Modal />
            </Router>
          )}
        </PoulesContextProvider>
      </PlayersContextProvider>
    </AppContextProvider>
  );
};

export default App;
