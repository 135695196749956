import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// import { AppContext } from "../contexts/AppContext";
import { PoulesContext } from "../contexts/PoulesContext";
import { PlayersContext } from "../contexts/PlayersContext";
import { http } from "../services/http";
import { leadingZero } from "../services/number";
import ErrorMessage from "./ErrorMessage";
import { ReactComponent as LoaderIcon } from "../icons/loader.svg";

const getTime = () => {
  const now = new Date(),
    time = `_${leadingZero(now.getDate())}-${leadingZero(
      now.getMonth() + 1
    )}-${now.getFullYear()}_${leadingZero(now.getHours())}-${leadingZero(
      now.getMinutes()
    )}`;

  return time;
};

const DownloadModal = ({ onClose }) => {
  const { players } = useContext(PlayersContext);
  const { poules } = useContext(PoulesContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  const handlePlayersDownload = async () => {
    setLoading("players");
    setError(false);
    const response = await http(`/export/players${location.search}`, {
      method: "POST",
      body: JSON.stringify({ players }),
    });

    const contentType = response.headers.get("Content-Type");

    if (!response.ok) {
      if (contentType === "application/json") {
        const data = await response.json();
        setError({
          type: "other",
          message: data.message || "Er is een onbekende fout opgetreden.",
        });
      }
    } else {
      if (contentType === "application/pdf") {
        const blob = await response.blob();
        download(blob, `deelnemerslijst${getTime()}.pdf`);
        setLoading(false);
      } else {
        setError({
          type: "pdf",
          message: "Er is iets fout gegaan bij het genereren van de PDF.",
        });
      }
    }

    ReactGA.event({
      category: "Gebruikersinteracties",
      action: "Download PDF",
      label: "Player",
    });

    setLoading(false);
  };

  const handlePoulesDownload = async () => {
    setLoading("poules");
    setError(false);
    const response = await http(`/export/poules${location.search}`, {
      method: "POST",
      body: JSON.stringify({ poules, players }),
    });

    const contentType = response.headers.get("Content-Type");

    if (!response.ok) {
      if (contentType === "application/json") {
        const data = await response.json();
        setError({
          type: "other",
          message: data.message || "Er is een onbekende fout opgetreden.",
        });
      }
    } else {
      if (contentType === "application/pdf") {
        const blob = await response.blob();
        download(blob, `poules${getTime()}.pdf`);
        setLoading(false);
      } else {
        setError({
          type: "pdf",
          message: "Er is iets fout gegaan bij het genereren van de PDF.",
        });
      }
    }

    ReactGA.event({
      category: "Gebruikersinteracties",
      action: "Download PDF",
      label: "Poules",
    });

    setLoading(false);
  };

  const handleContestNotesDownload = async () => {
    setLoading("contest-notes");
    setError(false);
    const response = await http(`/export/contest-notes${location.search}`, {
      method: "POST",
      body: JSON.stringify({ poules, players }),
    });

    const contentType = response.headers.get("Content-Type");

    if (!response.ok) {
      if (contentType === "application/json") {
        const data = await response.json();
        setError({
          type: "other",
          message: data.message || "Er is een onbekende fout opgetreden.",
        });
      }
    } else {
      if (contentType === "application/pdf") {
        const blob = await response.blob();
        download(blob, `wedstrijdbriefjes${getTime()}.pdf`);
        setLoading(false);
      } else {
        setError({
          type: "pdf",
          message: "Er is iets fout gegaan bij het genereren van de PDF.",
        });
      }
    }

    ReactGA.event({
      category: "Gebruikersinteracties",
      action: "Download PDF",
      label: "Contest Notes",
    });

    setLoading(false);
  };

  const download = (data, name) => {
    const url = URL.createObjectURL(data);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.target = "_blank";
    anchor.download = name || "dartschema.pdf";
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(function () {
      anchor.remove();
      URL.revokeObjectURL(url);
    }, 100);
  };

  return (
    <>
      <h3>Download</h3>
      <p>Ingevulde schema's (PDF)</p>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
      <button
        className="c-button c-button--expanded"
        onClick={handlePoulesDownload}
        disabled={loading}
      >
        <span>Poule schema's</span>{" "}
        {loading && loading === "poules" && (
          <LoaderIcon className="animate-spin" />
        )}
      </button>
      <button
        className="c-button c-button--expanded"
        onClick={handlePlayersDownload}
        disabled={loading}
      >
        <span>Deelnemerslijst</span>{" "}
        {loading && loading === "players" && (
          <LoaderIcon className="animate-spin" />
        )}
      </button>
      <button
        className="c-button c-button--expanded"
        onClick={handleContestNotesDownload}
        disabled={loading}
      >
        <span>Wedstrijdbriefjes</span>{" "}
        {loading && loading === "contest-notes" && (
          <LoaderIcon className="animate-spin" />
        )}
      </button>
    </>
  );
};

export default DownloadModal;
