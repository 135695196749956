import React, { useRef, useEffect } from "react";

import { CSSTransition } from "react-transition-group";

import { X } from "react-feather";

const GlobalModal = ({ show, onClose, children }) => {
  const node = useRef(null);
  const nodeWrapper = useRef(null);

  useEffect(() => {
    const handleClick = (e) => {
      if (node.current && node.current.contains(e.target)) {
        return;
      }
      onClose();
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [onClose]);

  return (
    <CSSTransition
      in={show}
      timeout={200}
      classNames="fade"
      nodeRef={nodeWrapper}
      unmountOnExit
    >
      <div
        className="c-modal c-modal--global c-modal--center"
        ref={nodeWrapper}
      >
        <div className="c-modal__dialog" ref={node}>
          {children}
          <button
            className="c-control-button c-modal__close"
            type="button"
            onClick={onClose}
          >
            <X />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default GlobalModal;
