import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import ReactGA from "react-ga";

import { createFormats, getOccurences, createPoules } from "../modules/poule";

import { AppContext } from "../contexts/AppContext";
import { PlayersContext } from "../contexts/PlayersContext";
import { PoulesContext } from "../contexts/PoulesContext";

import { X, ArrowRight, Info } from "react-feather";

const Modal = () => {
  const { players } = useContext(PlayersContext);
  const { setPoules, pouleSettings } = useContext(PoulesContext);
  const { settings, setSettings } = useContext(AppContext);
  const [formats, setFormats] = useState(() => {
    return createFormats(players.length, pouleSettings.min, pouleSettings.max);
  });
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const node = useRef(null);
  const nodeWrapper = useRef(null);

  const isDisabled = value === "";

  const handleChange = (e) => {
    ReactGA.event({
      category: "Gebruikersinteracties",
      action: "Select number of poules",
      value: +e.target.value,
    });

    setValue(+e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const poules = createPoules(players, value);
    setPoules(poules);
    navigate(`/poules${location.search}`);
    close();

    ReactGA.event({
      category: "Gebruikersinteracties",
      action: "Generate poules",
      value: poules.length,
    });
  };

  const close = useCallback(() => {
    const s = { ...settings };
    s.modal = false;
    setSettings(s);
  }, [settings, setSettings]);

  useEffect(() => {
    const handleClick = (e) => {
      if (node.current && node.current.contains(e.target)) {
        return;
      }
      close();
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [close]);

  useEffect(() => {
    setValue("");
  }, [players.length, formats]);

  useEffect(() => {
    setFormats(
      createFormats(players.length, pouleSettings.min, pouleSettings.max)
    );
  }, [players, pouleSettings.min, pouleSettings.max]);

  // const handleChangeSettings = (values) => {
  //   const s = { ...pouleSettings };
  //   s.min = +values[0];
  //   s.max = +values[1];
  //   setPouleSettings(s);
  // };

  return (
    <CSSTransition
      in={settings.modal}
      timeout={200}
      classNames="fade"
      nodeRef={nodeWrapper}
      unmountOnExit
    >
      <div className="c-modal c-modal--center" ref={nodeWrapper}>
        <div className="c-modal__dialog" ref={node}>
          <h3>Kies een indeling</h3>
          <div className="c-alert">
            <div className="c-alert__icon">
              <Info />
            </div>
            <div className="c-alert__content">
              Deze applicatie gaat uit van minimaal 3 en maximaal 6 personen in
              een poule.
            </div>
          </div>
          <form className="c-form" onSubmit={handleSubmit}>
            {formats.length ? (
              <>
                <p>
                  <strong>{formats.length}</strong> mogelijke{" "}
                  {formats.length === 1 ? "indeling" : "indelingen"}
                </p>
                <ul className="c-grouping-list">
                  {formats.map((format) => {
                    const occurences = getOccurences(format);
                    return (
                      <li key={format.length}>
                        <div className="c-group-option">
                          <input
                            type="radio"
                            name="grouping"
                            id={`grouping-${format.length}`}
                            value={format.length}
                            checked={value === format.length}
                            onChange={handleChange}
                          />
                          <label htmlFor={`grouping-${format.length}`}>
                            <div>
                              <div>
                                <strong>
                                  {format.length}{" "}
                                  {format.length === 1 ? "poule" : "poules"}
                                </strong>
                              </div>
                              <small>
                                {[...occurences.keys()].map((value, idx) => {
                                  return (
                                    <React.Fragment key={idx}>
                                      {occurences.get(value)}{" "}
                                      {occurences.get(value) === 1
                                        ? "poule"
                                        : "poules"}{" "}
                                      van {value} <br />
                                    </React.Fragment>
                                  );
                                })}
                              </small>
                            </div>
                          </label>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <p>
                <strong>Er zijn geen indelingen mogelijk.</strong>
              </p>
            )}
            <div className="c-modal__footer">
              <button
                className="c-button c-button--expanded"
                disabled={isDisabled}
                type="submit"
              >
                {isDisabled ? (
                  "Selecteer een indeling"
                ) : (
                  <>
                    <span>
                      {value} {value === 1 ? "poule" : "poules"} maken
                    </span>{" "}
                    <ArrowRight />
                  </>
                )}
              </button>
            </div>
          </form>
          <button
            className="c-control-button c-modal__close"
            type="button"
            onClick={close}
          >
            <X />
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
