import React, { createContext, useState, useEffect } from "react";

// import { AppContext } from "./AppContext";

export const PlayersContext = createContext(null);

const PlayersContextProvider = ({ children }) => {
  // const { resetToken } = useContext(AppContext);

  const [players, setPlayers] = useState(() => {
    const localData = sessionStorage.getItem("players");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    sessionStorage.setItem("players", JSON.stringify(players));
  }, [players]);

  // useEffect(() => {
  //   resetToken();
  // }, [players, resetToken]);

  return (
    <PlayersContext.Provider value={{ players, setPlayers }}>
      {children}
    </PlayersContext.Provider>
  );
};

export default PlayersContextProvider;
