import React from "react";
import { XOctagon } from "react-feather";

const ErrorMessage = ({ children }) => {
  return (
    <div className="c-alert c-alert--error">
      <div className="c-alert__icon">
        <XOctagon />
      </div>
      <div className="c-alert__content">
        {children} <br /> Laat het ons weten via{" "}
        <a
          href="https://m.me/dartschema"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        .
      </div>
    </div>
  );
};

export default ErrorMessage;
